import React, { useEffect, useRef } from 'react';
import { Header } from './components/header/Header'
import { Footer } from './components/footer/Footer'
import TwoColMultiMedia from './components/twocolmultimedia';
import backgroundVideo from './assets/images/background-top.mp4';
import { Code2, Laptop, Rocket, Server, Smartphone, Workflow, Globe, Share2, Store } from 'lucide-react';

function App() {
  const parallaxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrolled = window.scrollY;
        parallaxRef.current.style.transform = `translateY(${scrolled * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div>
      <Header />
      <div className="relative overflow-hidden min-h-[calc(100vh_-_73px)] md:h-[980px] flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat">
        <div className="absolute inset-0 bg-cover bg-center bg-no-repeat background-video">
          <video
            className="absolute inset-0 w-full h-full object-cover"
            src={backgroundVideo}
            autoPlay
            loop
            muted
            playsInline
          ></video></div>
          <div className="container mx-auto relative z-10 h-full px-4">
            <div className="lg:w-8/12 w-full mx-auto h-full flex flex-col">
              <div className="flex flex-col gap-y-8 h-full justify-center py-[60px] flex-1 lg:text-center">
                <div className="text-content cms-content">
                  <h1 className="text-white text-4xl font-bold">Sólo hay un límite...<br />Tu imaginación.</h1>
                  <p className="text-white">Construimos productos digitales a la medida,<br />para impulsar el crecimiento de tu empresa, negocio o emprendimiento.</p>
                </div>
                <div className="flex lg:flex-nowrap flex-wrap gap-x-4 gap-y-4 justify-center">
                  <a className="btn btn--primary py-2 px-4 rounded" to="https://api.whatsapp.com/send/?phone=584122540247&text&type=phone_number&app_absent=0">COTIZAR AHORA</a>
                  <a className="btn btn--secondary py-2 px-4 rounded" href="#services">Más información</a>
                </div>
              </div>
            </div>
          </div>
        </div>
          <section id="scale-your-team" className="pt-20 sm:pt-32 lg:pt-40 pb-10 sm:pb-16 lg:pb-20 mb-16 relative">
            <div className="container mx-auto">
              <div className="lg:text-center lg:justify-center lg:items-center relative z-10 flex">
                <div className="w-full lg:w-10/12 flex flex-col gap-y-8">
                  <div className="text-content cms-content" id="services">
                    <h2 className="text-4xl font-bold">
                      <span>TE OFRECEMOS APOYO</span><br />
                      <span>INTEGRAL DESDE EL DISEÑO</span><br />
                      <span style={{ color: '#44bb89' }}>HASTA EL LANZAMIENTO</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*<TwoColMultiMedia
            imageSrc="appweb.png"
            imageAlt="APLICACIONES WEB"
            title="APLICACIONES WEB"
            text="Desarrollamos aplicaciones web personalizadas para tu negocio, combinando diseño innovador y funcionalidad avanzada. Garantizamos soluciones rápidas, seguras y adaptables, que potencian tu presencia en línea y optimizan tus procesos."
            buttonText="COTIZAR AHORA"
            buttonUrl="/contact"
          />
          <TwoColMultiMedia
            imageSrc="ecommerce.png"
            imageAlt="E-COMMERCE"
            title="E-COMMERCE"
            text="Desarrollamos tiendas de e-commerce personalizadas que capturan la atención de tus clientes. Diseñamos plataformas intuitivas, seguras y optimizadas para todos los dispositivos, facilitando una experiencia de compra fluida y efectiva."
            buttonText="COTIZAR AHORA"
            buttonUrl="/contact"
            reverse
          />
          <TwoColMultiMedia
            imageSrc="appmobile.png"
            imageAlt="APLICACIONES MÓVILES"
            title="APLICACIONES MÓVILES"
            text="Creamos aplicaciones móviles a medida para iOS y Android, ofreciendo una experiencia de usuario excepcional. Desde la idea inicial hasta el lanzamiento, desarrollamos soluciones que cumplen tus objetivos y destacan en el mercado."
            buttonText="COTIZAR AHORA"
            buttonUrl="/contact"
          />*/}

<div className="min-h-screen bg-gradient-to-b from-gray-100 to-white text-black">
      {/* Hero Section */}
      {/*<div className="relative h-screen flex items-center overflow-hidden">
        <div ref={parallaxRef} className="absolute inset-0 z-0">
          <img
            src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80"
            alt="Technology Background"
            className="w-full h-full object-cover opacity-20"
          />
        </div>
        <div className="container mx-auto px-6 z-10">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 space-y-8 animate-fade-in">
              <h1 className="text-5xl md:text-7xl font-bold font-montserrat leading-tight">
                Transformamos Ideas en <span className="text-blue-400">Realidad Digital</span>
              </h1>
              <p className="text-xl text-gray-300">
                Desarrollamos soluciones tecnológicas innovadoras para impulsar tu negocio hacia el futuro.
              </p>
              <button className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-semibold transition-all transform hover:scale-105">
                Comienza tu Proyecto
              </button>
            </div>
            <div className="md:w-1/2 mt-12 md:mt-0">
              <div className="relative animate-float">
                <img
                  src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?auto=format&fit=crop&q=80"
                  alt="Desarrollo"
                  className="rounded-2xl shadow-2xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      <div className="py-12 bg-white">
        <div className="container mx-auto px-6">
        <div className="text-content cms-content">
          <h2 className="text-4xl md:text-5xl font-bold font-montserrat text-center mb-8">Nuestros Servicios</h2>
        </div>
          <p className="text-xl text-gray-600 text-center mb-16 max-w-3xl mx-auto">
            Ofrecemos una gama completa de servicios tecnológicos para transformar tu visión en realidad
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: <Code2 size={40} />,
                title: 'Desarrollo Web',
                desc: 'Creamos aplicaciones web modernas y escalables que destacan en el mercado digital',
                img: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?auto=format&fit=crop&q=80',
                features: [
                  'Aplicaciones web progresivas (PWA)',
                  'Interfaces responsivas',
                  'Optimización de rendimiento',
                  'Integración con APIs'
                ]
              },
              {
                icon: <Smartphone size={40} />,
                title: 'Apps Móviles',
                desc: 'Desarrollamos aplicaciones nativas y multiplataforma que cautivan a tus usuarios',
                img: 'https://images.unsplash.com/photo-1551650975-87deedd944c3?auto=format&fit=crop&q=80',
                features: [
                  'Apps nativas iOS y Android',
                  'Experiencia de usuario fluida',
                  'Integración con hardware',
                  'Actualizaciones OTA'
                ]
              },
              {
                icon: <Server size={40} />,
                title: 'Backend',
                desc: 'Construimos infraestructuras robustas que potencian tu aplicación',
                img: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?auto=format&fit=crop&q=80',
                features: [
                  'Arquitectura escalable',
                  'APIs RESTful',
                  'Bases de datos optimizadas',
                  'Seguridad avanzada'
                ]
              },
              {
                icon: <Workflow size={40} />,
                title: 'DevOps',
                desc: 'Automatizamos y optimizamos tus procesos de desarrollo y despliegue',
                img: 'https://images.unsplash.com/photo-1551434678-e076c223a692?auto=format&fit=crop&q=80',
                features: [
                  'Integración continua',
                  'Despliegue automatizado',
                  'Monitoreo en tiempo real',
                  'Gestión de infraestructura'
                ]
              },
              {
                icon: <Laptop size={40} />,
                title: 'UI/UX Design',
                desc: 'Diseñamos experiencias digitales que enamoran a tus usuarios',
                img: 'https://images.unsplash.com/photo-1561070791-2526d30994b5?auto=format&fit=crop&q=80',
                features: [
                  'Diseño centrado en usuario',
                  'Prototipos interactivos',
                  'Tests de usabilidad',
                  'Design systems'
                ]
              },
              {
                icon: <Rocket size={40} />,
                title: 'Consultoría',
                desc: 'Guiamos tu transformación digital con estrategias personalizadas',
                img: 'https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80',
                features: [
                  'Análisis de necesidades',
                  'Planificación estratégica',
                  'Optimización de procesos',
                  'Capacitación técnica'
                ]
              },
            ].map((service, index) => (
              <div
                key={index}
                className="group relative overflow-hidden rounded-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
              >
                <div className="absolute inset-0">
                  <img
                    src={service.img}
                    alt={service.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-black/80 to-black/75"></div>
                </div>
                <div className="relative p-8">
                  <div className="text-blue-400 mb-4 transform group-hover:scale-110 transition-transform">
                    {service.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-4 text-white">{service.title}</h3>
                  <p className="text-gray-300 mb-6">{service.desc}</p>
                  <ul className="space-y-2">
                    {service.features.map((feature, fIndex) => (
                      <li key={fIndex} className="flex items-center text-gray-400 text-sm">
                        <div className="w-1.5 h-1.5 bg-blue-400 rounded-full mr-2"></div>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-24 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-content cms-content">
            <h2 className="text-4xl md:text-5xl font-bold font-montserrat text-center mb-8">Soluciones para tu Negocio</h2>
          </div>
          <p className="text-xl text-gray-600 text-center mb-16 max-w-3xl mx-auto">
            Creamos soluciones digitales que impulsan el crecimiento de tu empresa, sin importar el tamaño o sector
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            {[
              {
                icon: <Store size={48} />,
                title: "Tiendas en Línea",
                desc: "Vende tus productos 24/7 con una tienda online profesional",
                img: "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&q=80",
                features: [
                  "Catálogo de productos",
                  "Pagos en línea",
                  "Gestión de inventario",
                  "Panel administrativo"
                ]
              },
              {
                icon: <Smartphone size={48} />,
                title: "Aplicaciones Móviles",
                desc: "Llega a tus clientes donde estén con apps nativas para iOS y Android",
                img: "https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?auto=format&fit=crop&q=80",
                features: [
                  "Diseño intuitivo",
                  "Notificaciones push",
                  "Modo offline",
                  "Actualizaciones automáticas"
                ]
              },
              {
                icon: <Globe size={48} />,
                title: "Páginas Web y Landing Pages",
                desc: "Destaca en internet con un sitio web profesional y optimizado",
                img: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80",
                features: [
                  "Diseño responsive",
                  "Optimización SEO",
                  "Formularios de contacto",
                  "Analytics integrado"
                ]
              },
              {
                icon: <Share2 size={48} />,
                title: "Marketing Digital y Redes Sociales",
                desc: "Aumenta tu presencia online y conecta con tu audiencia",
                img: "https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80",
                features: [
                  "Gestión de redes sociales",
                  "Campañas publicitarias",
                  "Contenido estratégico",
                  "Análisis de resultados"
                ]
              }
            ].map((solution, index) => (
              <div key={index} className="group">
                <div className="relative overflow-hidden rounded-xl mb-8">
                  <img
                    src={solution.img}
                    alt={solution.title}
                    className="w-full h-64 object-cover transform group-hover:scale-105 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-60"></div>
                  <div className="absolute bottom-6 left-6 text-white">
                    <div className="text-blue-400 mb-2">{solution.icon}</div>
                    <h3 className="text-2xl font-bold font-montserrat">{solution.title}</h3>
                  </div>
                </div>
                <div className="space-y-4">
                  <p className="text-black font-bold text-lg">{solution.desc}</p>
                  <ul className="space-y-2">
                    {solution.features.map((feature, fIndex) => (
                      <li key={fIndex} className="flex items-center text-gray-400">
                        <div className="w-1.5 h-1.5 bg-blue-400 rounded-full mr-2"></div>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* About Us Section */}
      <div className="py-24 bg-gray-800">
        <div className="container mx-auto px-6">
          <div className="flex flex-col lg:flex-row items-center gap-16">
            <div className="lg:w-1/2">
              <div className="relative">
                <img
                  src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80"
                  alt="Equipo MOID Tech"
                  className="rounded-2xl shadow-2xl"
                />
                <div className="absolute inset-0 bg-blue-500/10 rounded-2xl"></div>
              </div>
            </div>
            <div className="lg:w-1/2 space-y-8">
              <div className="text-content cms-content">
                <h2 className="text-4xl md:text-5xl font-bold mb-8 text-white">Acerca de nosotros</h2>
              </div>
              <div className="space-y-6 text-gray-300">
                <p className="text-lg leading-relaxed">
                  MOID Tech es una empresa especializada en el desarrollo de software a medida, creando soluciones innovadoras para negocios que buscan optimizar sus procesos y escalar con tecnología. Nuestro enfoque modular y flexible permite a nuestros clientes integrar fácilmente nuevas funcionalidades y adaptarse a las cambiantes necesidades del mercado.
                </p>
                <p className="text-lg leading-relaxed">
                  En MOID Tech, transformamos ideas en soluciones tecnológicas de alto impacto. Nos apasiona crear software que no solo funcione, sino que impulse el crecimiento de nuestros clientes. Ya sea que necesites una plataforma robusta, una aplicación escalable o herramientas de automatización, nuestro equipo está listo para hacer que la tecnología trabaje a tu favor.
                </p>
              </div>
              <div className="flex gap-4">

              <a className="btn btn--primary py-2 px-4 rounded" to="#team">Conoce al Equipo</a>
              <a className="btn btn--secondary py-2 px-4 rounded text-white" href="#services">Ver Proyectos</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact CTA Section */}
      <div className="py-24">
        <div className="container mx-auto px-6 text-center">
          <div className="text-content cms-content">
            <h2 className="text-4xl md:text-5xl font-bold mb-8">¿Listo para Digitalizar tu Negocio?</h2>
          </div>
          <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
            Contáctanos hoy y descubre cómo podemos ayudarte a crecer en el mundo digital
          </p>
          <a className="btn btn--primary py-2 px-4 rounded" to="https://api.whatsapp.com/send/?phone=584122540247&text&type=phone_number&app_absent=0">Solicitar Consulta Gratuita</a>
        </div>
      </div>
    </div>

        <Footer />
    </div>
  );
}

export default App;
